import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import {
  ssrThemeProps,
  getVisitorId,
  getApolloSsrClient,
} from '@stitch-fix/sf-next/server';
import querystring from 'querystring';
import Script from 'next/script';
import { modularPageWrapperSchema } from '../../components/ModularPageWrapper/schema';
import { getCachedEntryByUrl } from '../../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../../utils/loggers/server';
import { ModularPageWrapper } from '../../components/ModularPageWrapper';
import {
  getReactivationUrl,
  isDynamicAdsLandingPage,
  shouldAllocateSupVisitor,
} from '../../utils/redirects';
import { allocateVisitorSsr } from '../../utils/allocateVisitorSsr/allocateVisitorSsr';
import {
  BLUESHIFT_MODAL_EXPERIMENT,
  SUP_REDIRECT_EXPERIMENT_NAME,
} from '../../utils/helpers';
import {
  isAllSettledResponseFailure,
  logResponsesFromAllSettledSSR,
} from '../../utils/promises';
import { getDynamicAdsRequest } from '../../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../../features/dynamicAds/utils/getModulesWithHeroOverride';

export type WomenPageProps = z.infer<typeof modularPageWrapperSchema> & {
  displayBlueshiftModal?: boolean;
};

export const getServerSideProps: GetServerSideProps<WomenPageProps> = async ({
  locale,
  req,
  query,
}) => {
  const url = req.url || '';
  const stringifiedQuery = querystring.stringify(query);

  const reactivationUrl = getReactivationUrl(url);

  if (reactivationUrl) {
    return {
      redirect: {
        destination: `${reactivationUrl}?${stringifiedQuery}`,
        permanent: false,
      },
    };
  }

  const visitorId = getVisitorId(req);
  const apolloSsrClient = getApolloSsrClient({ req });

  try {
    const entryRequest = await getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/womens-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    let dynamicAdsRequest: ReturnType<typeof getDynamicAdsRequest> | null =
      null;

    // only call the dynamic ads algos if the page is a dynamic ads landing page
    if (isDynamicAdsLandingPage(url)) {
      dynamicAdsRequest = getDynamicAdsRequest({ req });
    }

    // If a visitor to /women or /auto/d/women has params indicating they're a SUP client
    // we allocate them to the SUP Landing Page experiment and redirect the treatment visitors to a SUP page
    const supExperimentRequest = shouldAllocateSupVisitor(url)
      ? allocateVisitorSsr({
          req,
          visitorId,
          apolloSsrClient,
          configParams: [
            {
              expectedValues: ['control', 'treatment'],
              fallbackValue: 'control',
              name: SUP_REDIRECT_EXPERIMENT_NAME,
            },
          ],
        })
      : null;

    // Blueshift Modal Experiment
    const blueshiftModalExperimentRequest = allocateVisitorSsr({
      req,
      visitorId,
      apolloSsrClient,
      configParams: [
        {
          expectedValues: ['control', 'treatment'],
          fallbackValue: 'control',
          name: BLUESHIFT_MODAL_EXPERIMENT,
        },
      ],
    });

    /**
     * this allows us to handle a dynamic number of requests in a non-synchronous way
     */
    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
      supExperimentRequest,
      blueshiftModalExperimentRequest,
    ]);

    logResponsesFromAllSettledSSR({
      responses,
      message: 'women data error',
      team: 'client-onboarding',
    });

    const [
      entryResponse,
      dynamicAdsResponse,
      supExperimentResponse,
      blueshiftModalResponse,
    ] = responses;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    const shouldRedirectToSupPage =
      supExperimentResponse.status === 'fulfilled' &&
      supExperimentResponse.value?.data?.visitor.configParams.some(
        p => p.name === SUP_REDIRECT_EXPERIMENT_NAME && p.value === 'treatment',
      );

    const shouldShowBlueshiftModal =
      (blueshiftModalResponse.status === 'fulfilled' &&
        blueshiftModalResponse.value?.data?.visitor.configParams.some(
          p => p.name === BLUESHIFT_MODAL_EXPERIMENT && p.value === 'treatment',
        )) ??
      false;

    if (shouldRedirectToSupPage) {
      return {
        redirect: {
          destination: `/women/your-first-fix?${stringifiedQuery}`,
          permanent: false,
        },
      };
    }
    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          businessLinePage: 'women',
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
        ...(await ssrThemeProps({
          req,
          overrideTheme: 'brand-2024-full',
        })),
        displayBlueshiftModal: shouldShowBlueshiftModal,
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'client-onboarding',
      message: 'Required page data is undefined - Women',
      context: {
        error: formatError(error),
        page: '/women',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Women page');
  }
};

const WomenPage: NextPage<WomenPageProps> = ({
  seoData,
  modules,
  displayBlueshiftModal,
}) => {
  return (
    <>
      {displayBlueshiftModal && (
        <Script
          // Blueshift web conversion integration (https://experiences.getblueshift.com/)
          src="https://www.lightboxcdn.com/vendor/3767d51a-4af9-4270-8ee4-c7310e4371b0/lightbox_speed.js"
          defer
        />
      )}
      <ModularPageWrapper seoData={seoData} modules={modules} />;
    </>
  );
};

export default WomenPage;
